import Typed from 'typed.js';

var options = {
  strings: [' connecté ', ' accessible ', ' multi-usage ', ' digital '],
  typeSpeed: 50,
  backSpeed: 0,
  backDelay: 1500,
  startDelay: 500,
  loop: true,
  cursorChar: ' ',
};
if(document.querySelector('#typewriter')) {
  var typed = new Typed('#typewriter', options);
}

