import $ from "jquery";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

$('#menu-trigger').on('click', function(){
    $('#mobile-menu').hasClass('mobile-active') ? $('#mobile-menu').removeClass('mobile-active') : $('#mobile-menu').addClass('mobile-active');
    $('.header').hasClass('mobile-active') ? $('.header').removeClass('mobile-active') : $('.header').addClass('mobile-active');
    $(this).addClass('mobile-active');
    if($('#mobile-menu').hasClass('mobile-active')) {
      disableBodyScroll();
    }
    else {
      enableBodyScroll();
    }
});
  
$('#mobile-menu ul li a').on('click', function(){
    $('#mobile-menu').removeClass('mobile-active');
    $('.header').removeClass('mobile-active');
    $('#menu-trigger').removeClass('mobile-active');
    enableBodyScroll();
});

$(window).scroll(function() {
    var scroll = $(window).scrollTop();
  
    const header = $('.header');
    const isAlwaysSticky = header.hasClass("always-sticky")
    const isDesktopSticky = header.hasClass("desktop-sticky")

    if (scroll >= 100) {
        header.addClass("sticky");
    } else if (!isAlwaysSticky) {
        header.removeClass("sticky");
    }

    if(isDesktopSticky && window.matchMedia("(max-width: 700px)").matches){
        header.removeClass("sticky");
    }
});