import $ from "jquery";

$("button.visite").on("click", function() {
  $(
    "#webfactory_participantbundle_participant_subject option[value=visite]"
  ).attr("selected", "selected");
});

$("button.devis").on("click", function() {
  $(
    "#webfactory_participantbundle_participant_subject option[value=devis]"
  ).attr("selected", "selected");
});
// permet d'aller chercher les paramètres de l'url
const query = window.location.search;

// fonction qui permet de d'aller en chercher un en particulier 'select' ou d'en rajouter
const params = new URLSearchParams(query);

if (params.get("select") && params.get("select") === "devis") {
  $(
    "#webfactory_participantbundle_participant_subject option[value=devis]"
  ).attr("selected", "selected");
}

$("button.question").on("click", function() {
  $(
    "#webfactory_participantbundle_participant_subject option[value=question]"
  ).attr("selected", "selected");
});

$(document).ready(function() {
  let typeContactAuto = $("#form").data("auto");
  // gestion pre-remplissage form en fonction url
  if (typeContactAuto && typeContactAuto != "") {
    switch (typeContactAuto) {
      case "visite":
        $(
          "#webfactory_participantbundle_participant_subject option[value=visite]"
        ).attr("selected", "selected");
        break;
      case "devis":
        $(
          "#webfactory_participantbundle_participant_subject option[value=devis]"
        ).attr("selected", "selected");
        break;
      case "question":
        $(
          "#webfactory_participantbundle_participant_subject option[value=question]"
        ).attr("selected", "selected");
        break;
    }
  }
});
