import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import './lib/datepicker-fr.js';
import 'timepicker/jquery.timepicker.min';

function initDatePicker(nbMonths) {
  $('.datepicker').datepicker({
    altField: "#alt-datepicker",
    altFormat: "dd/mm/yy",
    numberOfMonths: nbMonths,
    minDate: 0,
  });
}
function datePicker() {
  if(window.matchMedia("(max-width: 700px)").matches){
    initDatePicker(1)
  } else{
    initDatePicker(2)
  }
}

$( window ).resize(function() {
  $(".datepicker" ).datepicker( "destroy" );
  datePicker();
});

$(document).ready(function(){
  datePicker();

  $('.timepicker').timepicker({
    'timeFormat': 'H:i',
    'minTime': '7:30am',
    'maxTime': '09:00pm',
    useSelect: true
  });

  // Set Room Title
  $('.card-date').on('click', function(){
    const room =  $(this).data('room');

    $("#avail .modal__title, #form_avail .modal__title").html(room);
    $('#form_avail .modal__title').html(room);
  });

  // Set default values to time inputs
  var now = new Date();
  var m = (Math.round(now.getMinutes()/30) * 30) % 60;
  now.setMinutes(m);
  var inOneHour = addHoursToDate(now, 1)
  $('#time-start').timepicker('setTime', now);
  $('#time-end').timepicker('setTime', inOneHour);

  $('.validateDate').on('click', function(){
    const room = $(".modal__title").html();
    const date = $("#alt-datepicker").val();
    const timeStart = $("#time-start").val();
    const timeEnd = $("#time-end").val();

    // Set reminder text
    var dateParts = date.split('/');
    var mydate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    var options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
    $(".text_date").html(mydate.toLocaleDateString('fr', options));
    $(".text_time-start").html(timeStart);
    $(".text_time-end").html(timeEnd);

    var myDateStart = new Date(mydate.valueOf());
    var timeStartParts = timeStart.split(':');
    myDateStart.setHours(timeStartParts[0]);
    myDateStart.setMinutes(timeStartParts[1]);

    var myDateEnd = new Date(mydate.valueOf());
    var timeEndParts = timeEnd.split(':');
    myDateEnd.setHours(timeEndParts[0]);
    myDateEnd.setMinutes(timeEndParts[1]);

    // Set inputs
    $("#webfactory_participantbundle_participant_room").val(room);
    $("#webfactory_participantbundle_participant_dateStart").val(myDateStart);
    $("#webfactory_participantbundle_participant_dateEnd").val(myDateEnd);

  });

  $('.toggle-content').on('click', function(){
    toggleAvailibilitiesContent();
  });
});

function addHoursToDate(date, hours) {
  return new Date(new Date(date).setHours(date.getHours() + hours));
}

export function resetAvailibilitiesContent() {
  if ($(".modal__avail").hasClass('hide')) {
    $(".modal__avail").removeClass('hide');
    $(".modal__avail-form").addClass('hide');
  }
}
function toggleAvailibilitiesContent() {
  $(".modal__avail").toggleClass('hide');
  $(".modal__avail-form").toggleClass('hide');
}
