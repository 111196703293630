import Swiper, { Navigation, Scrollbar, Controller, EffectFade, Pagination } from "swiper";
Swiper.use([Navigation, Scrollbar, Controller, EffectFade, Pagination]);

const myswiper = new Swiper(".swiper-container", {
  direction: "horizontal",
  initialSlide: 0,
  slidesPerView: 3,
  mousewheel: true,
  paginationClickable: true,
  lazy: true,
  watchOverflow: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
  },
  breakpoints: {
    1280: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
  }
});
if(document.querySelector('.swiper-container')) {
  myswiper.init();
}
