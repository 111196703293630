import $ from "jquery";
import MicroModal from "micromodal";


$("body").on("click", "[data-zoom]", (event) => {
    openFlyerPopin($(event.currentTarget));
});

const openFlyerPopin = ( cible ) => {
	$('.modal__zoom').children().remove()
    const templateSelector = $(cible).data("zoom");
    const template = $(`[data-zoom-template="${templateSelector}"]`);
    $("[data-modal-zoom]").append(template.html());
}

$( document ).ready(function() {
    let isOpenFlyerAuto = $("#flyer").data("auto");
    if ( isOpenFlyerAuto )  {
      openFlyerPopin($('#btnFlyer'));
      MicroModal.show('zoom')
      $("#flyer").data("auto", "");
    }
});
