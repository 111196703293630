import $ from "jquery";
import MicroModal from "micromodal";
import {resetAvailibilitiesContent} from "./disponibilites"

export default class Modal {
  constructor() {
    this.init();
  }

  clearModal = (element) => {
    $("body").removeClass("modal-is-visible");
    $(".modal__zoom")
      .children()
      .remove();
    const modalType = element.data("modal");

    switch (modalType) {
      case "embed":
        element.find("[data-modal-embed]").attr("src", "");
        break;

      case "zoom":
        $(".modal__zoom").children().remove();
        break;

      case "avail":
        resetAvailibilitiesContent();
        break;

      default:
        console.warn("modalType not found !");
        break;
    }
  };

  init = () => {
    MicroModal.init({
      awaitCloseAnimation: true,
      disableScroll: true,
      onClose: (el) => this.clearModal($(el)),
      onShow: () => $("body").addClass("modal-is-visible"),
    });
  };
}

$("body").on("click", "[data-embed]", event => {
  $("[data-modal-embed]").attr("src", $(event.currentTarget).data("embed"));
});

$('.modal__zoom__close').on('click', function(){
  // $('#embed').remove();
  MicroModal.close('zoom')
  $('.modal__zoom').children().remove()
});

$('.modal__avail__close').on('click', function(el){
  MicroModal.close("avail");
});