import $ from "jquery";
import "./polyfill";
import Modal from "./modal";
import "./typewriter";
import "./mySwiper";
import "./pushSlide";
import "./header";
import "./form";
import "./flyer";
import "./disponibilites";
import inView from "in-view";

new Modal();
const div = $("[data-container]");
// on stock le data-sticky de chaque section
const sections = $("[data-sticky]");

// on stock les li
const menuItems = $(".item-nav");

// inView permet de déclencher une action à l'apparition de la section sur l'écran
inView("[data-container]").on("enter", (el) => {
  //activeItem stock le li qui a la classe active
  const activeItem = document.querySelector(".item-nav.active");
  //console.log(activeItem, "activeItem");

  // on lui supprime la classe active
  activeItem && activeItem.classList.remove("active");
  // console.log(activeItem, "activeItem remove");

  // newActiveItem stock le data-target du li actif
  const newActiveItem = document.querySelector(`[data-target="${el.id}"]`);
  //console.log(newActiveItem, "newActiveItem");

  // on lui ajoute la classe active
  newActiveItem && newActiveItem.classList.add("active");
  // console.log(newActiveItem, "newActiveItem remove");
});

// au scroll
$(document).on("scroll", function() {
  // on boucle sur les li
  menuItems.each((index, item) => {
    // la fonction getBoundingClientRect retourne un objet qui permet de connaitre la position d'un élément ainsi que sa taille par rapport à sa zone d'affichage
    // on stock la position du li par rapport à sa zone d'affichage
    const itemRect = item.getBoundingClientRect();

    // on boucle sur les sections
    div.each((sIndex, section) => {
      sections.each((id, sec) => {
        //on stock l'information du scroll de la section par rapport à la zone d'affichage
        const sectRect = sec.getBoundingClientRect();

        // si le haut de la section est inférieur ou égal au haut du li et que le bas de la section est supérieur ou égal au bas du li
        if (
          sectRect.top <= itemRect.top &&
          sectRect.bottom >= itemRect.bottom
        ) {
          // stickyType stock le data-sticky de la section en cours
          const stickyType = $(sec).data("sticky");

          // on stock le menu
          const menuNav = document.querySelector("[data-sticky-nav]");

          // si le data-sticky n'est pas de type hidden
          if (stickyType !== "hidden") {
            // on supprime la classe hidden du menu
            menuNav.classList.remove("hidden");

            // on lui supprime la classe standard et alternate
            item.classList.remove("standard", "alternate");

            // et on lui ajoute la bonne classe
            item.classList.add(stickyType);
          } else {
            // sinon on ajoute la classe hidden au menu
            menuNav.classList.add("hidden");
          }
        }
      });
    });
  });
});

$(document).on("click", 'a[href^="#"]', function(event) {
  var href = $.attr(this, "href");
  var headerHeight = document.querySelector("#head").offsetHeight;

  $("html, body").animate(
    {
      scrollTop: $(href).offset().top - headerHeight,
    },
    500
  );
});

function accordion() {
  $(".accordion-panel").slideUp();
  $(".accordion").on("click", ".accordion-toggle", function(e) {
    e.preventDefault();
    // On check si celui cliqué avait la classe
    var wasActive = $(this).hasClass("active");
    $(".accordion-toggle").removeClass("active");
    // On ajoute la classe uniquement s'il l'avait pas
    if (!wasActive) $(this).addClass("active");
    $(this)
      .siblings(".accordion-panel")
      .slideToggle();
    $(".accordion-toggle")
      .not(".active")
      .siblings(".accordion-panel")
      .slideUp();
  });
  $(".accordion-toggle")
    .first()
    .click();
}

$(document).ready(function() {
  accordion();
});
