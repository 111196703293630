import Swiper from "swiper";
// import { swiperDefaults } from "../config";

class PushSlide {
  constructor(container) {
    this.container = container;

    this.slider = container.querySelector("[data-pushs-slides]");
    this.prev = container.querySelector("[data-pushs-prev]");
    this.next = container.querySelector("[data-pushs-next]");
    this.scrollbar = container.querySelector("[data-pushs-scrollbar]");

    this.instance = null;

    this.init();
  }

  init = () => {
    this.instance = new Swiper(this.slider, {
      ...swiperDefaults({ scrollbarEl: this.scrollbar }),
      navigation: {
        nextEl: this.next,
        prevEl: this.prev,
      },
    });
  };
}

export default PushSlide;
